<template>
  <div ref="controlPanelElementRef" class="control-panel">
    <div class="panel">
      <div class="navigation">
        <SiteHeaderContent class="content" />
      </div>
      <div class="filterlist">
        <div class="type-list-container">
          <div class="type-list" :class="{ '-open': typeListOpen }">
            <button class="toggle" type="button" @click="typeListOpen = true">
              <span class="label">{{ $t('navigation.openOrClose') }}</span>
              <svg
                class="icon"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9976 14.7305L7.755 10.4878L8.96416 9.27868L11.9977 12.3122L15.033 9.2768L16.2422 10.486L11.9996 14.7286L11.9976 14.7305Z"
                  fill="#A8A8A8"
                />
              </svg>
            </button>
            <ul class="list">
              <li class="item" :class="{ '-selected': !filter?.articleType }">
                <NuxtLink
                  :to="
                    getTargetRoute(
                      $t('contentHub.allArticlesSlug'),
                      ($route.params.topicSlug as string) ?? null,
                      ($route.query.view as string) ?? null,
                    )
                  "
                  class="link"
                  @click="typeListOpen = false"
                >
                  {{ dt('homeArticleTypeAll') }}</NuxtLink
                >
              </li>
              <li
                v-for="articleType in articleTypes"
                :key="`article-type${articleType.id}`"
                class="item"
                :class="{
                  '-selected':
                    filter?.articleType?.translatedSlug ===
                    articleType?.translatedSlug,
                }"
              >
                <NuxtLink
                  :to="
                    getTargetRoute(
                      articleType.translatedSlug as string,
                      ($route.params.topicSlug as string) ?? null,
                      ($route.query.view as string) ?? null,
                    )
                  "
                  class="link"
                  @click="typeListOpen = false"
                >
                  {{ articleType.label }}</NuxtLink
                >
              </li>
            </ul>
          </div>
        </div>

        <div
          class="topic"
          :class="{ '-open': topicSelectionOpen, '-selected': !!filter.topic }"
        >
          <Autocomplete
            ref="topicSelectionComponentRef"
            :model-value="filter?.topic"
            :items="topics"
            :label="(t) => t?.label ?? '?'"
            :clearable="true"
            :placeholder="$t('contentHub.topicPlaceholder')"
            class="selector"
            @update:model-value="
              topicSelectionOpen = false;
              navigateTo(
                getTargetRoute(
                  ($route.params.slug as string) ??
                    $t('contentHub.allArticlesSlug'),
                  $event?.translatedSlug ?? null,
                  ($route.query.view as string) ?? null,
                ),
              );
            "
          />
          <button
            class="toggle text-sm"
            type="button"
            :class="`-${view}`"
            @click="toggleTopicSelection()"
          >
            <svg
              v-if="filter.topic"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.0038 2.79608H1.65108C1.42427 2.79608 1.20674 2.88621 1.04639 3.04662C0.886038 3.20703 0.795996 3.42459 0.796082 3.6514C0.797033 6.17382 1.64793 8.62233 3.21145 10.6017C4.63403 12.4027 6.57101 13.7198 8.7568 14.3832V24.3489C8.7568 24.6643 8.93035 24.954 9.20836 25.1028C9.48637 25.2516 9.8237 25.2352 10.0861 25.0603L14.8625 21.8761C15.1004 21.7175 15.2432 21.4505 15.2432 21.1647V14.3832C17.429 13.7198 19.366 12.4027 20.7886 10.6018L20.7887 10.6017C21.4298 9.79003 21.951 8.89947 22.343 7.95728C21.7588 7.88063 21.2063 7.70312 20.7036 7.4428C20.3789 8.18879 19.9577 8.89485 19.4468 9.5418C18.1215 11.2195 16.2696 12.4018 14.1899 12.8977C13.8049 12.9895 13.5332 13.3335 13.5332 13.7293V20.7071L10.4668 22.7514V13.7293C10.4668 13.3335 10.1951 12.9895 9.81011 12.8977C7.73044 12.4018 5.87854 11.2195 4.55333 9.54181C3.40569 8.0889 2.71103 6.33781 2.54494 4.50608H18.2305C18.0806 4.0308 17.9997 3.52485 17.9997 3.00002C17.9997 2.93171 18.0011 2.86372 18.0038 2.79608ZM21.3151 5.48272C21.3785 5.1606 21.4253 4.83464 21.4551 4.50608H20.4046C20.1471 4.06345 19.9997 3.54895 19.9997 3.00002C19.9997 2.93148 20.002 2.86348 20.0065 2.79608H22.3489C22.5758 2.79608 22.7933 2.88621 22.9536 3.04663C23.114 3.20705 23.204 3.42461 23.2039 3.65143C23.2036 4.44562 23.119 5.23248 22.9545 5.99969C22.3472 5.99071 21.7837 5.80132 21.3151 5.48272Z"
                fill="#A8A8A8"
              />
              <circle cx="23" cy="3" r="3" fill="#97FA5A" />
            </svg>

            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_4002_4339)">
                <path
                  d="M22.349 1.65112H1.65112C1.652 3.98132 2.43806 6.24325 3.88243 8.07182C5.32679 9.90038 7.34518 11.1889 9.61184 11.7294V22.349L14.3883 19.1647V11.7294C16.6549 11.1889 18.6734 9.90038 20.1178 8.07182C21.562 6.24325 22.348 3.98132 22.349 1.65112Z"
                  stroke="#A8A8A8"
                  stroke-width="1.71"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4002_4339">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <button
          class="view text-sm"
          type="button"
          :class="`-${view}`"
          @click="
            navigateTo(
              getTargetRoute(
                ($route.params.slug as string) ??
                  $t('contentHub.allArticlesSlug'),
                ($route.params.topicSlug as string) ?? null,
                view === 'card' ? 'list' : 'card',
              ),
            )
          "
        >
          <span class="label">{{
            view === 'card' ? dt('cardView') : dt('listView')
          }}</span>
          <!-- prettier-ignore -->
          <svg
class="icon"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="card">
              <path
                d="M2.44727 8.71114V3.14314C2.44727 2.75876 2.80368 2.44714 3.24334 2.44714H12.7962C13.2358 2.44714 13.5923 2.75876 13.5923 3.14314V8.71114C13.5923 9.09553 13.2358 9.40714 12.7962 9.40714H3.24334C2.80368 9.40714 2.44727 9.09553 2.44727 8.71114Z"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.7607 8.71114V3.14314C16.7607 2.75876 17.1171 2.44714 17.5568 2.44714H20.757C21.1966 2.44714 21.5531 2.75876 21.5531 3.14314V8.71114C21.5531 9.09553 21.1966 9.40714 20.757 9.40714H17.5568C17.1171 9.40714 16.7607 9.09553 16.7607 8.71114Z"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <g class="list">
              <line
                x1="2.855"
                y1="17.645"
                x2="3.145"
                y2="17.645"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                x1="6.855"
                y1="17.645"
                x2="21.145"
                y2="17.645"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                x1="2.855"
                y1="21.645"
                x2="3.145"
                y2="21.645"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                x1="6.855"
                y1="21.645"
                x2="21.145"
                y2="21.645"
                stroke="currentColor"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import type { ArticleTopicFragment, ArticleTypeFragment } from '#gql';
import { useInternalI18n } from '~/datocms/useInternalI18n';
import SiteHeaderContent from '~/components/partials/SiteHeaderContent.vue';
import Autocomplete from '~/components/form/Autocomplete.vue';

export interface ArticleFilter {
  articleType: ArticleTypeFragment | null;
  topic: ArticleTopicFragment | null;
}

export type ArticleViewModel = 'card' | 'list';

const props = defineProps<{
  articleTypes: ArticleTypeFragment[];
  topics: ArticleTopicFragment[];
  filter: ArticleFilter;
  view: ArticleViewModel;
}>();

const { locale } = useI18n();
const { dt } = useInternalI18n();

function getTargetRoute(
  slug: string,
  topicSlug: string | null,
  view: string | null,
): RouteLocationRaw {
  return {
    name:
      locale.value === 'de'
        ? 'content-hub-slug___de___default'
        : `content-hub-slug___${locale.value}`,
    params: {
      slug,
      topicSlug,
    },
    query: { view },
  };
}

const typeListOpen = ref(false);
const topicSelectionOpen = ref(false);

const topicSelectionComponentRef = ref<typeof Autocomplete>();
function toggleTopicSelection() {
  topicSelectionOpen.value = !topicSelectionOpen.value;

  if (topicSelectionOpen.value && !props.filter.topic) {
    // @ts-ignore
    topicSelectionComponentRef.value?.focus();
  }
}

const controlPanelElementRef = ref<HTMLElement>();

onBeforeRouteUpdate(() => {
  if (
    !controlPanelElementRef.value ||
    !controlPanelElementRef.value.parentElement
  ) {
    return;
  }

  const isPinned =
    controlPanelElementRef.value.classList.contains('-is-pinned');

  if (isPinned) {
    const controlPanelTop =
      controlPanelElementRef.value.parentElement.getBoundingClientRect().y -
      document.body.getBoundingClientRect().y;

    window.scrollTo({
      top: controlPanelTop,
    });
  }
});

onMounted(() => {
  let lastScrollPosition = 0;

  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('-is-pinned', e.intersectionRatio < 1),
    { threshold: [1] },
  );

  if (controlPanelElementRef.value) {
    observer.observe(controlPanelElementRef.value);
  }

  document.addEventListener('scroll', () => {
    if (!controlPanelElementRef.value) {
      return;
    }

    const scrollPosition = document.documentElement.scrollTop;

    if (scrollPosition > lastScrollPosition) {
      controlPanelElementRef.value.classList.add('-is-scrolling-down');
      controlPanelElementRef.value.classList.remove('-is-scrolling-up');
    } else {
      controlPanelElementRef.value.classList.add('-is-scrolling-up');
      controlPanelElementRef.value.classList.remove('-is-scrolling-down');
    }

    lastScrollPosition = window.scrollY;
  });
});
</script>

<style scoped lang="scss">
.control-panel {
  position: sticky;
  top: -1px;
  z-index: var(--z-header);

  @media (--vs) {
    margin-inline: -0.5rem;
  }

  @media (--vl) {
    padding-top: 0.5rem;
    margin-inline: -2rem;
  }

  &.-is-pinned > .panel {
    background: rgba(41, 41, 41, 0.2);
    box-shadow:
      0 128px 51px 0 rgba(0, 0, 0, 0.06),
      0 72px 43px 0 rgba(0, 0, 0, 0.2),
      0 32px 32px 0 rgba(0, 0, 0, 0.34),
      0 8px 18px 0 rgba(0, 0, 0, 0.39);
    backdrop-filter: blur(120px);

    > .navigation,
    > .filterlist {
      transition: transform var(--base-transition-duration)
        var(--base-transition-duration);
    }
  }

  &.-is-scrolling-up.-is-pinned > .panel {
    > .navigation {
      transform: translateY(0);
    }

    > .filterlist {
      transform: translateY(100%);
    }

    &:has(.type-list.-open),
    &:has(.v-select.vs--open) {
      > .navigation {
        transform: translateY(-100%);
      }

      > .filterlist {
        transform: translateY(0);
      }
    }
  }

  > .panel {
    position: relative;
    overflow: hidden;

    &:has(.type-list.-open),
    &:has(.v-select.vs--open) {
      overflow: visible;

      > .navigation {
        display: none;
      }
    }

    @media (--vs) {
      padding-inline: 0;

      &:has(.topic.-open) {
        > .filterlist {
          > .type-list-container,
          > .view {
            display: none;
          }
        }
      }
    }

    @media (--vl) {
      padding-inline: 2rem;
      border-radius: var(--base-border-radius);
    }
  }

  > .panel > .navigation {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);

    @media (--vs) {
      padding-inline: 1.5rem;
    }

    @media (--vl) {
      padding-inline: 2rem;
      padding-block: 1.25rem;
    }

    > .content {
      width: 100%;
    }
  }

  > .panel > .filterlist {
    display: flex;
    align-items: center;
    gap: var(--spacing-xl);
    padding-block: 1.25rem;

    @media (--vs) {
      padding-left: 0.5rem;
      padding-right: 1rem;
      gap: 0;
    }
  }

  > .panel > .filterlist > .topic {
    position: relative;

    > .toggle {
      appearance: none;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      flex-shrink: 0;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @media (--vs) {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      padding: 0 0.5rem;

      > .selector {
        flex: 1;
      }

      &.-open {
        width: 100%;
      }

      &:not(.-open) {
        > .selector {
          @include hide-visually;
        }
      }
    }

    @media (--vl) {
      min-width: 300px;

      > .toggle {
        display: none;
      }
    }
  }

  > .panel > .filterlist > .view {
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1.5rem;
    gap: var(--spacing-md);
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
    cursor: pointer;

    > .label {
      white-space: nowrap;

      @media (--vs) {
        @include hide-visually;
        position: relative;
      }
    }

    > .icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.-card > .icon .list {
      opacity: 0.5;
    }

    &.-list > .icon .card {
      opacity: 0.5;
    }

    @media (--vs) {
      padding-right: 0.5rem;
    }
  }
}
</style>

<style lang="scss">
.type-list-container {
  container-type: inline-size;
  width: 100%;
  line-height: 0;
}

.type-list {
  display: inline-block;
  min-width: 17rem;

  > .list > .item > .link {
    text-decoration: none;
  }

  @container (max-width: 629px) {
    height: 3.2rem;
    overflow: hidden;
    position: relative;
    border-radius: var(--base-border-radius);

    @media (--vs) {
      width: 92%;
    }

    @media (--vl) {
      width: 100%;
    }

    &.-open {
      overflow: visible;

      > .toggle {
        pointer-events: none;

        > .icon {
          rotate: 180deg;
        }
      }

      > .list {
        background: rgba(34, 34, 34, 0.96);
      }
    }

    > .toggle {
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: end;
      appearance: none;
      background: none;
      border: none;
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      z-index: 20;
      cursor: pointer;
      padding: 0;
      pointer-events: initial;

      > .label {
        @include hide-visually;
      }

      > .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        margin-left: auto;
      }
    }

    > .list {
      background: rgba(20, 20, 20, 0.6);
      border-radius: var(--base-border-radius);
      display: grid;
      overflow: hidden;

      > .item.-selected {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
      }

      > .item > .link {
        display: block;
        font-size: 1rem;
        line-height: 1;
        padding: 1.12rem 1rem;
        border-top: 1px solid #202020;

        @media (hover: hover) {
          &:hover {
            background-color: var(--color-stone-900);
          }
        }
      }

      > .item:first-child > .link {
        border-top: none;
      }
    }
  }

  @container (min-width: 630px) {
    > .toggle {
      display: none;
    }

    > .list {
      display: flex;
      gap: 2rem;

      > .item {
        cursor: pointer;
        color: var(--color-stone-300);

        &.-selected {
          > .link {
            color: var(--color-lime-400);
          }
        }
      }
    }
  }
}
</style>
